import { Box, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
// import { useTranslation } from "react-i18next";
import CustomStepper from "../../customstepper/customStepper";
import Layout from "../Layout";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  headerContainer: {
    textAlign: "center",
  },
  logo: {
    width: "100%",
    maxWidth: 200,
  },
  title: {
    fontFamily: "Montserrat-Bold",
    marginBottom: "1rem",
    color: "#fff",
  },
  infoText: {
    fontSize: "16px",
    fontStyle: "italic",
    color: "#fff",
  },
}));

function HomePage() {
  // const { t } = useTranslation();
  const classes = useStyles();
  // const { packType } = useParams()
  // if (!packType || packType < 0 || packType > 2) return <NotFoundPage />
  return (
    <Layout title={"Créez votre plateforme !"} className={classes.root}>
      <Grid container className="container">
        <Grid item lg={6}>
          <Container
            style={{
              backgroundColor: "#303030",
              borderTopRightRadius: "4%",
              borderBottomRightRadius: "4%",
              minHeight: "100vh",
            }}
          >
            <Box className={classes.headerContainer} mb={2}>
              <img
                className={classes.logo}
                src="https://media.fteledition.fr/Uploads/02f55acb-860c-494f-8903-58d37e24e94d/Logo_PGM_Blanc_sans_phrase.png"
              />
              <Typography variant="h4" component="p" className={classes.title}>
                Créez votre plateforme
              </Typography>
              <Typography className={classes.infoText}>
                Afin de créer gratuitement votre plateforme de test aux couleurs
                de votre entreprise, nous avons besoin de quelques informations
                vous concernant vous et votre société. Ces informations seront
                uniquement utilisées pour vous recontacter et faciliter nos
                échanges. *
              </Typography>
            </Box>
            <CustomStepper />
          </Container>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default HomePage;
