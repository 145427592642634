import { Container, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import "../../../styles/global.scss"
import Layout from "../Layout"

const useStyles = makeStyles((theme) => ({
  background: {
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    objectFit: "cover",
    zIndex: "-1",
  },
  container: {
    paddingTop: theme.spacing(25),
    textAlign: "center",
  },
  text: {
    color: "#FFF",
    fontSize: "23px",
    marginBottom: theme.spacing(2),
  },
  logo: {
    marginTop: theme.spacing(6),
    maxWidth: "20rem",
    width: "50%",
  },
  bold: {
    fontFamily: "Montserrat-Bold",
  },
}))

function FinishPage() {
  const classes = useStyles()
  document.documentElement.style.setProperty(
    "--bg-image",
    `url("https://pgm-media.fteledition.fr/Uploads/91d32df9-f140-4255-b48c-d68e1a57df8f/PGMBackground.jpg")`
  )
  return (
    <Layout title={"Merci !"} className={classes.layoutContainer}>
      <>
        <Container className={classes.container}>
          <Typography component='h1' className={classes.text}>
            Votre plateforme Pronostic Games est en cours de création par nos équipes.
          </Typography>
          <Typography component='h1' className={classes.text}>
            Nous reviendrons <span className={classes.bold}>rapidement vers vous</span> pour vous donner l'accès.
          </Typography>
          <img className={classes.logo} src='https://pgm-media.fteledition.fr/Uploads/2a87b50f-9113-4e1e-aabd-f2572c4a31c0/LogoBlanc.png' />
        </Container>
      </>
    </Layout>
  )
}

export default FinishPage
