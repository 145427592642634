import { yupResolver } from "@hookform/resolvers/yup";
import {
 Box,
 Button,
 CircularProgress,
 Container,
 IconButton,
 InputAdornment,
 TextField,
 Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Send } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import "../../../styles/global.scss";
import { verifyCode } from "../../../_actions/enterprise_actions";
import Layout from "../Layout";
import LoadingPage from "./../../LoadingPage";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import LangsSwitch from "../../LangsSwitch";
import i18n from "../../../i18n";

const useStyles = makeStyles((theme) => ({
 root: {
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
 },
 headerContainer: {
  textAlign: "center",
 },
 logo: {
  width: "100%",
  maxWidth: 200,
 },
 title: {
  fontFamily: "Montserrat-Bold",
  marginBottom: "1rem",
  textAlign: "center",
 },
 infoText: {
  fontSize: "13px",
  fontStyle: "italic",
 },
 form: {
  maxWidth: 400,
  margin: "auto",
 },
 plateformButton: {
  fontWeight: "bold",
 },
 selectPlateformImage: {
  width: "65%",
  maxWidth: "30rem",
 },
 switch: {
  position: "absolute",
  top: theme.spacing(2),
  right: theme.spacing(2),
 },
}));

// const localStorageCodeKey = "ApplicationCode";
// const getLocalStorageCode = () =>
//  window.localStorage.getItem(localStorageCodeKey);
// const setLocalStorageCode = (code) =>
//  window.localStorage.setItem(localStorageCodeKey, code);
// setLocalStorageCode("FTEL2022");
// console.log("sauvegarder");
// console.log("le code sauvegarder :", getLocalStorageCode());
document.documentElement.style.setProperty("--bg-image", `undefined`);
function EnterprisePage() {
 const { t } = useTranslation();
 const classes = useStyles();
 const [localVerified, setLocalVerified] = React.useState(false);
 const [plateforms, setPlateforms] = React.useState([]);

 const validationSchema = Yup.object().shape({
  code: Yup.string().required("Ce champ est requis"),
 });

 const {
  register,
  handleSubmit,
  setError,
  errors,
  formState: { isSubmitting },
 } = useForm({
  resolver: yupResolver(validationSchema),
 });

 useEffect(() => {
  const code = Cookies.get("ApplicationCode");
  if (code) {
   verifyCode({
    code: code,
   }).then((r) => {
    if (!r.error) {
     if (r.plateformsUrlAndName.length === 1) {
      // Une seule plateforme correspondante à ce code entreprise
      window.location = r.plateformsUrlAndName[0].url;
     } else {
      // Plusieurs plateformes correspondates à ce code entreprise
      setPlateforms(r.plateformsUrlAndName);
     }
    } else {
     setLocalVerified(true);
    }
   });
  } else {
   setLocalVerified(true);
  }
 }, []);

 if (!localVerified) return <LoadingPage />;

 const onFormSubmit = (values) => {
  return new Promise((resolve) => {
   setTimeout(() => {
    let code = values.code;
    verifyCode({
     code: code,
    }).then((r) => {
     if (r.error) {
      if (resolve) {
       setError("code", {
        type: "manual",
        message: "Ce code n'est pas associé à une entreprise",
       });
       resolve();
      }
     } else {
      if (r.plateformsUrlAndName.length === 1) {
       // Une seule plateforme correspondante à ce code entreprise
       //  setLocalStorageCode(code);
       window.location = r.plateformsUrlAndName[0].url;
      } else {
       // Plusieurs plateformes correspondates à ce code entreprise
       setPlateforms(r.plateformsUrlAndName);
      }
     }
    });
   }, 250);
  });
 };

 function handleSelectPlateform(url) {
  window.location = url;
 }

 if (!i18n.isInitialized) {
  return <LoadingPage />;
 }

 return (
  <Layout
   title={"Accéder à la plateforme de votre entreprise"}
   className={classes.root}
  >
   <Container>
    {plateforms.length <= 1 ? (
     <>
      <Box className={classes.headerContainer} mb={4}>
       <img
        className={classes.logo}
        src="https://media.fteledition.fr/Uploads/03a681fe-a7ff-46b5-9af8-e1f8f8989590/LOGO_VECTO_Plan_de_travail_1.svg"
        alt="logo pronosticgames"
       />
       <Typography variant="h4" component="p" className={classes.title}>
        {t("enterprise.title")}
       </Typography>
       <Typography className={classes.infoText}>
        {t("enterprise.subtitle")}
       </Typography>
      </Box>
      <form className={classes.form} onSubmit={handleSubmit(onFormSubmit)}>
       <Box>
        <TextField
         name="code"
         label={t("enterprise.code")}
         inputRef={register}
         variant="outlined"
         fullWidth
         InputProps={{
          endAdornment: (
           <InputAdornment position="end">
            <IconButton type="submit" disabled={isSubmitting}>
             {isSubmitting ? <CircularProgress /> : <Send />}
            </IconButton>
           </InputAdornment>
          ),
         }}
        />

        {errors.code && (
         <Box mt={1}>
          <Alert severity="error">{errors.code.message}</Alert>
         </Box>
        )}
       </Box>
      </form>
     </>
    ) : (
     <>
      <Typography variant="h4" component="p" className={classes.title} my={10}>
       Veuillez sélectionner votre plateforme
      </Typography>
      <Box className={classes.headerContainer}>
       <img
        className={classes.selectPlateformImage}
        src="https://media.fteledition.fr/Uploads/b658c414-3111-4c90-abd2-9f4e496873cc/1_Vacation_V1.svg"
        alt="submit"
       ></img>
      </Box>
      <Box display="flex" justifyContent="center">
       {plateforms.map((plateform, i) => {
        return (
         <Box display="inline" mx={3}>
          <Button
           className={classes.plateformButton}
           variant="contained"
           color="primary"
           onClick={() => handleSelectPlateform(plateform.url)}
          >
           {plateform.name}
          </Button>
         </Box>
        );
       })}
      </Box>
     </>
    )}
    <LangsSwitch className={classes.switch} />
   </Container>
  </Layout>
 );
}

export default EnterprisePage;
