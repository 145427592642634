import { Box, Grid, TextField } from '@material-ui/core';
import React from 'react';
import RenderInput from './RenderInput';

export default function ContactForm(props) {
  const {
    formField: {
      firstName,
      lastName,
      email,
      phone,
      societyName,
    }
  } = props;

  return (
    <Box >
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <RenderInput name={lastName.name} label={lastName.label} autoFocus={true} />
        </Grid>
        <Grid item xs={6}>
          <RenderInput name={firstName.name} label={firstName.label} />
        </Grid>
        <Grid item xs={6}>
          <RenderInput name={email.name} label={email.label} />

        </Grid>
        <Grid item xs={6}>
          <RenderInput name={phone.name} label={phone.label} />
        </Grid>
        <Grid item xs={6}>
          <RenderInput name={societyName.name} label={societyName.label} />
        </Grid>
      </Grid>
    </Box>
  );
}