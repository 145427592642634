import React from 'react';
import InputField from '../../formFields/inputField';

function RenderInput(props) {
  const fullWidth = props.fullWidth ?? true
  const margin = props.margin ?? "dense"
  const variant = props.variant ?? "standard"


  return (<InputField {...props} fullWidth={fullWidth} margin={margin} variant={variant}  />)
}

export default RenderInput