import MomentUtils from "@date-io/moment";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ConfirmProvider } from "material-ui-confirm";
import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";
import "../styles/global.scss";
import theme from "../styles/theme";
import EnterprisePage from "./views/EnterprisePage/EnterprisePage";
import FinishPage from "./views/FinishPage/FinishPage.js";
import HomePage from "./views/HomePage/HomePage";
import NotFoundPage from "./views/NotFoundPage/NotFoundPage";
import PackPickerPage from "./views/PackPickerPage";

function App() {
  return (
    <Suspense fallback="loading">
      <ThemeProvider theme={theme}>
        <Helmet /*htmlAttributes={{ lang : i18n.language }}*/>
          <meta name="theme-color" content={theme.palette.background.default} />
        </Helmet>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ConfirmProvider
            defaultOptions={
              {
                // title:t("common.confirm.dialog.title"),
                // confirmationText:t("common.confirm.dialog.button.ok"),
                // cancellationText:t("common.confirm.dialog.button.nok"),
              }
            }
          >
            <CssBaseline />
            <Switch>
              <Route exact path="/pack" component={PackPickerPage} />
              <Route exact path="/enterprise" component={EnterprisePage} />
              <Route exact path="/" component={HomePage} />
              <Route exact path="/finish" component={FinishPage} />
              <Route component={NotFoundPage} />
            </Switch>
          </ConfirmProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
