import checkoutFormModel from './createPlateformFormModel';

const {
  formField: {
    firstName,
    lastName,
    email, 
    phone, 
    societyName, 
    societyAddress,
    societyZipCode,
    societyCity,
    plateformName,
    plateformUrl,
    forCustomer, 
    forEmployee,
    forPartner,
    nbUsers,
    logo,
    primaryColor,
  }
} = checkoutFormModel;

export default {
  [firstName.name]: '',
  [lastName.name]: '',
  [email.name]: '',
  [phone.name]: '',
  [societyName.name]: '',
  [societyAddress.name]: '',
  [societyZipCode.name]: '',
  [societyCity.name]: '',
  [plateformName.name]: '',
  [plateformUrl.name]: '',
  [forCustomer.name]: false,
  [forEmployee.name]: false,
  [forPartner.name]: false,
  [nbUsers.name]: 2,
  [logo.name]: '',
  [primaryColor.name]: '#000000',
};
