import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';

export const primary = '#FBBA33';
export const secondary = '#FBBA33';

// Create a theme instance.
const theme = createMuiTheme({ 
  palette: {
    type: "light",
    primary: { main: primary },
    secondary: { main: secondary },
    background: {
      default:"#fff"
    },
    tonalOffset:0.2,
    error: { 
      main: red.A700,
    },
  },
  shape : {
    borderRadius:3
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  typography: {
    fontFamily: [
      'Montserrat-Regular',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export default theme;