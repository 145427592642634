import { Box, FormHelperText, Grid, IconButton, InputLabel, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageIcon from '@material-ui/icons/Image';
import UploadDropZone from "@rpldy/upload-drop-zone";
import Uploady, { UploadyContext, useItemFinishListener } from "@rpldy/uploady";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    container:{
        padding:theme.spacing(1.5),
        border:"1px solid " + theme.palette.grey["400"],
        borderRadius:theme.shape.borderRadius
    },
    cursorPointer: {
        cursor:"pointer"
    },
    imgPreview: {
        borderRadius:theme.shape.borderRadius,
        overflow:"hidden",
        position:"relative",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        color:theme.palette.grey["400"],
        border:"none",
        "& > img": {
            maxWidth:"100%",
            maxHeight:"100%",
            position:"absolute",
            top:"50%",
            left:"50%",
            transform: "translateX(-50%) translateY(-50%)",
            objectFit:"contain"
        },
        '&.withBorders' : {
            border: "1px dashed " + theme.palette.grey["400"],
        }
    },
    fileName:{
        flexGrow:1,
        color: "inherit",
        '&.grey' : {
            color: theme.palette.grey["600"]
        }
    }
}));

const UploadPreviewer = ({name, value, onChange, imgWidth = 150, helperText, showFilename = false}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const uploady = useContext(UploadyContext);
    const [url, setUrl] = React.useState(value);

    useItemFinishListener((item) => {
        setUrl(item.uploadResponse.data);
    });

    const onClick = useCallback(()=> {
        uploady.showFileUpload();
    }, [uploady]);

    const onDelete = () => {
        setUrl("")
    }

    React.useEffect(() => {
        if(onChange) onChange(url)
    }, [url])

    const imgUrl = url + `?width=${imgWidth}&height=${imgWidth}`;

    return (
        <>
            {name && <Box mb={0.25}><InputLabel shrink>{name}</InputLabel></Box>}
            <Box className={classes.container}>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={"auto"} onClick={onClick} className={classes.cursorPointer}>
                        <Box className={classes.imgPreview + " " + (url ? "" : "withBorders")} style={{width:imgWidth, height:imgWidth}}>
                            {url ? <img src={imgUrl} alt=""/> : <ImageIcon/> }
                        </Box>
                    </Grid>
                    <Grid item xs className={classes.fileName + " " + (showFilename ? classes.cursorPointer : "") + " " + (url ? "" : "grey")} onClick={(e) => showFilename ? onClick(e) : null}>
                        {showFilename && 
                            <Typography variant="body2">{ url ? /[^/]*$/.exec(url)[0] : "Déposez votre logo ici"}</Typography>
                        }
                    </Grid>
                    {url && 
                        <Grid item xs={"auto"}>
                            <Box textAlign="right">
                                <IconButton size="small" aria-label="delete" onClick={onDelete}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
                {helperText && <Box ml={1}><FormHelperText>{helperText}</FormHelperText></Box>}
        </>
    );
}

export default function UploadFile({name, value, uploadUrl, onChange, previewWidth, helperText, showFilename = true}) {
    const finalUploadUrl = uploadUrl ?? process.env.REACT_APP_PUBLIC_MEDIA_URL;

    return (
        <Uploady destination={{ url: finalUploadUrl }}>
            <UploadDropZone>
                <Box mt={-1}>
                    <UploadPreviewer showFilename={showFilename} name={name} value={value} onChange={onChange} imgWidth={previewWidth} helperText={helperText} />
                </Box>
            </UploadDropZone>
        </Uploady>
    );
};