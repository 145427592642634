import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import utilStyles from '../styles/utils.module.scss'

export default function FullHeightCenter({children, variant, fullWidth, fullHeightChild}) {
    const content = () => {
        switch(variant){
            case "paper":
                return (<Paper><Box p={2}>{children}</Box></Paper>);
            default:
                return children;
        }
    }

    const childClasses = (fullWidth ? utilStyles.fullWidth : "") + " " + (fullHeightChild ? utilStyles.fullHeight : "")
    return (
        <>
            <style global="true" jsx="true">{`
                html,
                body,
                body > div#root, body > div#root > div, div#main-container{
                    height: 100%;
                }
                div#main-container > main {
                    position:absolute;
                    top:50%;
                    left:0;
                    right:0;
                    transform:translateY(-50%)
                }
            `}</style>
            <Grid container direction="row" alignItems="center" justify="center" className={utilStyles.fullHeight}>
                <Grid item className={childClasses}>
                    <Box className={fullHeightChild ? utilStyles.fullHeight : ""} textAlign="center">
                        {content}
                    </Box>
                </Grid> 
          </Grid>
        </>
    )
}