import axios from "axios";
import { EMAIL_SERVER } from "../components/Config.js";

axios.defaults.headers.post["Content-Type"] = "application/json";

export function sendEmail(dataToSubmit) {
  return axios
    .post(`${EMAIL_SERVER}/send-mail`, dataToSubmit)
    .then((response) => response.data);
}
