import {
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { sendEmail } from "../../../../_actions/email_actions";
const imgS = 100;

const PackDetailsCard = withStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "&>img": {
      marginBottom: theme.spacing(0.5),
      objectFit: "contain",
    },
  },
  bold: {
    fontWeight: "700",
  },
  checkedIcon: {
    fill: theme.palette.success.main,
  },
  row: {
    [theme.breakpoints.up("md")]: {
      minHeight: 45,
    },
  },
  textAlignCenter: {
    justifyContent: "center",
    display: "flex",
  },
  optionsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  btnContainer: {
    textAlign: "center",
    "&>button": {
      background: "#FCAE17",
      color: "#fff",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      fontWeight: "600",
      "&:hover": {
        color: "black",
      },
    },
  },
  paperContainer: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(2),
  },
  counter: {
    display: "flex",
    flexDirection: "row",
  },
  numberPlayers: {
    fontWeight: "700",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  counterContainer: {
    display: "inline-flex",
    marginTop: -theme.spacing(2),
    marginBottom: -theme.spacing(2),
  },
  text: {
    textAlign: "center",
    fontSize: "1rem",
    fontWeight: 700,
  },
  detail: {
    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
      "& > p": {
        position: "relative",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  },
}))(({ item, packType, values, classes }) => {
  const history = useHistory();

  const handleClick = useCallback(
    (type, nb) => {
      const valuesToSend = { ...values, TypePack: type, NbUsers: nb };
      const toSend = JSON.stringify(valuesToSend, null, 2);
      sendEmail(toSend);
      history.push("/finish");
    },
    [history, values]
  );

  const [value, setValue] = useState(item.options[0].value);

  const handleIncrement = (opt) => {
    const index = opt.step.findIndex((e) => e === value);
    if (index >= opt.step.length - 1) {
      return;
    }
    setValue(opt.step[index + 1]);
  };

  const handleDecrement = (opt) => {
    const index = opt.step.findIndex((e) => e === value);
    if (index === 0) {
      return;
    }
    setValue(opt.step[index - 1]);
  };

  return (
    <Paper variant="outlined" className={classes.paperContainer}>
      <div className={classes.header}>
        <img src={item.img} alt="" height={imgS} width={imgS} />
        <Typography variant="h5" className={classes.bold}>
          {item.name}
        </Typography>
        <Typography color="textSecondary"> {item.price}</Typography>
      </div>
      <div className={classes.optionsContainer}>
        {item.options.map((opt, index) => {
          return (
            <Grid
              container
              spacing={2}
              key={index}
              alignItems="center"
              className={classes.row}
            >
              <Grid
                item
                lg={3}
                md={4}
                xs={5}
                className={classes.textAlignCenter}
              >
                {opt.value !== "0" ? (
                  opt.step ? (
                    <div className={classes.counterContainer}>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => handleDecrement(opt)}
                        disabled={value <= 50}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                      <p className={classes.text}>{value}</p>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => handleIncrement(opt)}
                        disabled={value >= 250}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <Typography className={classes.bold}>
                      {opt.value}
                    </Typography>
                  )
                ) : (
                  <CheckCircleIcon className={classes.checkedIcon} />
                )}
              </Grid>
              <Grid item md xs={7} className={classes.detail}>
                <Typography
                  title={opt.text}
                  className={opt.isBoldText ? classes.bold : ""}
                >
                  {opt.text}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </div>
      <Grid item xs={12} className={classes.btnContainer}>
        <Button
          variant="contained"
          onClick={() => handleClick(packType, value)}
        >
          testez gratuitement
        </Button>
      </Grid>
    </Paper>
  );
});
export default PackDetailsCard;
