import { Box, Container, Fade, Grid } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import "../app.css";

function Layout({ title, props, children, className }) {
  return (
    <Box>
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <main>
        <Fade in={true}>{children}</Fade>
      </main>
    </Box>
  );
}

export default Layout;
