import { Box, Button, Menu, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Langs from "../i18n/Langs";

const useStyles = makeStyles(() => ({
 btn: {
  minWidth: 0,
 },
}));

export default function LangsSwitch({ className }) {
 const { i18n } = useTranslation();
 const [lang, setLang] = useState(i18n.language);
 const [anchorEl, setAnchorEl] = React.useState(null);
 const classes = useStyles();

 const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 const changeLanguage = (key) => {
  setLang(key);
  moment.locale(key);
  i18n.changeLanguage(key);
  window.localStorage.setItem("lang", key);
  setAnchorEl(null);
 };

 return (
  <Box className={className}>
   <Button
    className={classes.btn}
    variant="outlined"
    color="secondary"
    size="small"
    aria-controls="simple-menu"
    aria-haspopup="true"
    onClick={handleClick}
   >
    {lang.split("-")[0]}
   </Button>
   <Menu
    id="simple-menu"
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={handleClose}
   >
    {Langs.map((item) => (
     <MenuItem onClick={() => changeLanguage(item.key)} key={item.key}>
      {item.key.split("-")[0].toUpperCase()}
     </MenuItem>
    ))}
   </Menu>
  </Box>
 );
}
