import { Container, Grid, Paper, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { withRouter } from "react-router-dom"
import Layout from "../Layout"
import NotFoundPage from "../NotFoundPage/NotFoundPage"
import PackDetailsCard from "./PackDetailsCard"

const packsDetails = [
  {
    img: "https://pgm-media.fteledition.fr/Uploads/7040ab3c-962a-4317-b349-d2873d1db38e/offreStarter.png",
    name: "Starter",
    price: "A partir de 150€ HT ",
    options: [
      {
        text: "Joueurs inclus",
        value: "50",
        step: ["50", "100", "250"],
      },
      {
        text: "Classement par équipe",
        value: "1",
      },
      {
        text: "Encart de communication",
        value: "1",
      },
      {
        text: "Application mobile",
        value: "0",
      },
      {
        text: "Plateforme personnalisable",
        value: "0",
      },
      {
        text: "URL à votre Nom",
        value: "0",
      },
      {
        text: "Quizz",
        value: "0",
      },
      {
        text: "Chat",
        value: "0",
      },
    ],
  },
  {
    img: "https://pgm-media.fteledition.fr/Uploads/499a23ab-fa9e-4715-b0a4-258c5b2d2bb2/offrePro.png",
    name: "Pro",
    price: "A partir de 1500€ HT",
    options: [
      {
        text: "Joueurs inclus",
        value: "1000",
      },
      {
        text: "Classement par équipe",
        value: "1",
      },
      {
        text: "Encart de communication",
        value: "3",
      },
      {
        text: "Fonctionnalité de l'offre Starter",
        value: "0",
        isBoldText: true,
      },
      {
        text: "Quizz personnalisable",
        value: "0",
      },
      {
        text: "Tribus",
        value: "0",
      },
      {
        text: "Sondage",
        value: "0",
      },
      {
        text: "Questions supplémentaires",
        value: "0",
      },
    ],
  },
  {
    img: "https://pgm-media.fteledition.fr/Uploads/aa219af9-fff6-4783-87b3-4fc553f2b2bf/offreExpert.png",
    name: "Expert",
    price: "Sur-mesure",
    options: [
      {
        text: "Joueurs inclus",
        value: "2000",
      },
      {
        text: "Classement par équipe",
        value: "illimité",
      },
      {
        text: "Encart de communication",
        value: "5",
      },
      {
        text: "Fonctionnalité de l'offre Pro",
        value: "0",
        isBoldText: true,
      },
      {
        text: "Marque blanche totale",
        value: "0",
      },
      {
        text: "URL intégrée à votre site",
        value: "0",
      },
      {
        text: "Parrinage des joueurs",
        value: "0",
      },
      {
        text: "Fonctionnalités à la demande",
        value: "0",
      },
    ],
  },
]
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paperContainer: {
    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(4)}px ${theme.spacing(7)}px ${theme.spacing(4)}px ${theme.spacing(7)}px`,
    },
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
  },
}))

const PackPickerPage = (props) => {
  const classes = useStyles()
  if (props.location.state === undefined) {
    return <NotFoundPage />
  }
  document.documentElement.style.setProperty(
    "--bg-image",
    `url("https://pgm-media.fteledition.fr/Uploads/a8d25c5f-6df2-402b-b4c5-3b3d68180661/MicrosoftTeams-image_(19).png")`
  )

  const data = props.location.state.data

  return (
    <Layout title={"Votre offre"}>
      <>
        <div className={classes.container}>
          <Container>
            <Paper className={classes.paperContainer}>
              <Typography variant='h4' align='center' gutterBottom>
                Choisissez votre offre, testez gratuitement
              </Typography>
              <Typography variant='body2' color='textSecondary' align='center'>
                Choisissez l'offre qui correspond à vos besoins, testez gratuitement notre outil puis payez ensuite. Si besoin, vous pourrez changer votre offre
                ultérieurement. Ainsi vos êtes sur d'avoir l'outil et l'offre faites pour votre entreprise !
              </Typography>
              <Grid container spacing={2} style={{ marginTop: "16px" }}>
                {packsDetails.map((item, index) => {
                  return (
                    <Grid item xs={12} md={4} key={"pack_" + index}>
                      <PackDetailsCard item={item} packType={index} values={data} />
                    </Grid>
                  )
                })}
              </Grid>
            </Paper>
          </Container>
        </div>
      </>
    </Layout>
  )
}

export default withRouter(PackPickerPage)
