export default {
    formId: 'createPlateformForm',
    formField: {
        lastName: {
            name: 'LastName',
            label: 'Nom *',
            requiredErrorMsg: 'Veuillez renseigner votre nom'
        },
        firstName: {
            name: 'FirstName',
            label: 'Prénom *',
            requiredErrorMsg: 'Veuillez renseigner votre prénom'
        },
        
        email: {
            name: 'Email',
            label: 'Email *',
            requiredErrorMsg: 'Veuillez renseigner votre adresse email',
            invalidEmail: 'Veuillez renseigner une adresse email correcte'
        },
        phone: {
            name: 'Phone',
            label: 'Téléphone *',
            requiredErrorMsg: 'Veuillez renseigner votre nuémro de téléphone'
        },
        societyName: {
            name: 'SocietyName',
            label: 'Entreprise/ Club *',
            requiredErrorMsg: 'Veuillez renseigner le nom de votre entreprise'
        },
        societyAddress: {
            name: 'SocietyAddress',
            label: 'Adresse de votre entreprise',
            requiredErrorMsg: 'Veuillez renseigner l\'adresse de votre entreprise'
        },
        societyZipCode: {
            name: 'SocietyZipCode',
            label: 'Code postal de votre entreprise',
            requiredErrorMsg: 'Veuillez renseigner le code postal de votre entreprise'
        },
        societyCity: {
            name: 'SocietyCity',
            label: 'Ville de votre entreprise',
            requiredErrorMsg: 'Veuillez renseigner la ville de votre entreprise'
        },
        plateformName: {
            name: 'PlateformName',
            label: 'Nom de votre jeu concours *',
            requiredErrorMsg: 'Veuillez renseigner le nom de votre jeu concours'
        },
        plateformUrl: {
            name: 'PlateformUrl',
            label: 'Url du jeu concours  *',
            requiredErrorMsg: 'Veuillez renseigner l\'URL de votre jeu concours'
        },
        forCustomer: {
            name: 'ForCustomer',
            label: 'Clients'
        },
        forEmployee: {
            name: 'ForEmployee',
            label: 'Salariés'
        },
        forPartner:{
            name: 'ForPartner', 
            label: 'Partenaires'
        },
        nbUsers: {
            name: 'NbUsers',
        },
        logo: {
            name: 'Logo',
            label: 'Votre logo (en blanc)*',
            requiredErrorMsg: 'Veuillez renseigner le logo de votre jeu concours'
        },
        primaryColor: {
            name: 'PrimaryColor',
            label: 'Votre couleur principale *',
            requiredErrorMsg: 'Veuillez renseigner la couleur principale de votre jeu concours'
        },
    }
};
