import { Box, Grid, InputAdornment } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ErrorMessage } from "formik"
import React from "react"
import ColorPicker from "../../../colorpicker/ColorPicker"
import UploadFile from "../../../upload/upload"
import RenderInput from "./RenderInput"
const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: "#d50000",
    marginLeft: "14px",
    marginBottom: "1rem",
    fontSize: "0.75rem",
  },
}))

const defaultPrimary = "#800020"
export default function ColorAndLogoForm(props) {
  const classes = useStyles()
  const {
    formField: { logo, primaryColor, plateformUrl },
    values,
    setFieldValue,
  } = props
  function handlePlateformNameChange(e) {
    const val = e.target.value
    const slugified = slugify(val)

    setFieldValue("PlateformName", val)
    setFieldValue("PlateformUrl", slugified)
  }

  const onBlurUrl = (url) => {
    let newVal = url
    if (url.substring(url.length - 1, url.length) === "-") {
      newVal = url.substring(0, url.length - 1)
    }
    setFieldValue("PlateformUrl", newVal)
  }

  const handleNbUsersChange = (e) => {
    let val = e.target.value
    setFieldValue("NbUsers", val)
  }
  function slugify(text) {
    return text
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters
      .normalize("NFD") // The normalize() method returns the Unicode Normalization Form of a given string.
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .trim() // Remove whitespace from both sides of a string
  }

  return (
    <Box pt={1}>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <RenderInput
            name={plateformUrl.name}
            label={plateformUrl.label}
            onChange={(e) => setFieldValue("PlateformUrl", slugify(e.target.value))}
            onBlur={() => onBlurUrl(values.PlateformUrl)}
            InputProps={{
              endAdornment: <InputAdornment position='end'>.pronosticgames.fr</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item lg={6} xs={12} md={12} sm={12}>
          <Box mb={2} mt={2}>
            <ColorPicker
              variant='outlined'
              label={primaryColor.label}
              onChange={(color) => {
                setFieldValue("PrimaryColor", color)
              }}
              fullWidth
              value={defaultPrimary}
            />
          </Box>
        </Grid>
        <Grid item lg={6} xs={12} md={12} sm={12}>
          <Box mb={2}>
            <UploadFile
              name={logo.label}
              value={values.Logo}
              previewWidth={100}
              onChange={(url) => {
                setFieldValue("Logo", url)
              }}
            />
          </Box>
        </Grid>

        <ErrorMessage component='p' name={logo.name} className={classes.errorMessage} />
      </Grid>
    </Box>
  )
}
