import { Box, CircularProgress, Grid, Hidden } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { sendEmail } from "../../_actions/email_actions";
import ColorAndLogoForm from "./createPlateformPage/form/colorAndLogoForm";
import ContactForm from "./createPlateformPage/form/contactForm";
import createPlateformFormModel from "./createPlateformPage/formModel/createPlateformFormModel";
import formInitialValues from "./createPlateformPage/formModel/formInitialValues";
import validationSchema from "./createPlateformPage/formModel/validationSchema";

const useStyles = makeStyles((theme) => ({
  actionButton: {
    textAlign: "right",
    marginTop: theme.spacing(2),
  },
  root: {
    "& .MuiPaper-root": { backgroundColor: "#303030" },
    "& .MuiTypography-root": { color: "#fff", fontSize: "100%" },
    "& .MuiStepConnector-line ": { borderTopStyle: "none" },
    "& .MuiSvgIcon-root ": { fontSize: "3rem" },
    "& .MuiInputBase-input": { color: "#fff" },
    "& .MuiFormLabel-root": { color: "#9D9D9D" },
    "& .MuiStepIcon-text": { fill: "#fff" },
  },
  ector: {
    display: "none",
  },
  bold: {
    fontFamily: "Montserrat-Bold",
    color: "#fff",
  },
  infoTextContainer: {
    marginBottom: "1rem",
  },
  infoText: {
    fontSize: "13px",
    fontStyle: "italic",
    textAlign: "left",
    color: "#fff",
  },
}));

function getSteps() {
  return ["Vos informations personnelles", "Votre jeu concours"];
}

const { formId, formField } = createPlateformFormModel;
function getStepContent(step, values, setFieldValue) {
  switch (step) {
    case 0:
      return <ContactForm formField={formField} />;
    case 1:
      return (
        <ColorAndLogoForm
          values={values}
          formField={formField}
          setFieldValue={setFieldValue}
        />
      );
  }
}

export default function CustomStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const classes = useStyles();
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const history = useHistory();
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function _sendMail(values) {
    const valuesToSend = { ...values };
    const toSend = JSON.stringify(valuesToSend, null, 2);
    sendEmail(toSend);
  }

  function _handleSubmit(values, actions) {
    // Envoie d'un email à chaque étape
    actions.setSubmitting(false);
    actions.setTouched({});
    _sendMail(values);

    if (activeStep === steps.length - 1) {
      history.push({
        pathname: "/pack",
        state: { data: values },
      });
    }

    setActiveStep(activeStep + 1);
  }

  return (
    <Box className={classes.root}>
      <Hidden smUp>
        <Stepper activeStep={activeStep} alternativeLabel connector={true}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Grid key={index} item lg={12}>
                <Step {...stepProps}>
                  <StepLabel {...labelProps}></StepLabel>
                </Step>
              </Grid>
            );
          })}
        </Stepper>
        <Box>
          <Typography
            className={classes.bold}
            gutterBottom
            variant="subtitle1"
            component="p"
          >
            {steps[activeStep]}
          </Typography>
        </Box>
      </Hidden>
      <Hidden xsDown>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={index} {...stepProps}>
                <StepLabel classes={{ label: classes.bold }} {...labelProps}>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Hidden>

      <Box>
        {activeStep !== steps.length ? (
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {(props) => {
              const { isSubmitting, values, setFieldValue, handleSubmit } =
                props;

              return (
                <Form id={formId} onSubmit={handleSubmit}>
                  {getStepContent(activeStep, values, setFieldValue)}

                  <Box className={classes.actionButton}>
                    <Grid container justify="flex-end">
                      <Grid
                        item
                        sm={12}
                        md={8}
                        className={classes.infoTextContainer}
                      >
                        <Typography className={classes.infoText}>
                          * Pour plus d'informations sur l'utilisation de vos
                          données personnelles, vous pouvez consulter notre
                          politique de confidentalité{" "}
                          <a
                            target="_blank"
                            href="https://www.pronosticgames.fr/politique-de-confidentialite"
                            style={{ color: "#fff" }}
                          >
                            ici
                          </a>
                          .
                        </Typography>
                      </Grid>

                      <Grid item sm={12} md={4}>
                        {activeStep !== 0 && (
                          <Box display="inline-block" mr={1}>
                            <Button
                              onClick={handleBack}
                              style={{ color: "#fff" }}
                            >
                              Retour
                            </Button>
                          </Box>
                        )}

                        <Button
                          type="submit"
                          style={{ color: "#fff", borderRadius: "8px" }}
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <CircularProgress size={24} />
                          ) : (
                            <>
                              {activeStep === steps.length - 1
                                ? "Créer ma plateforme"
                                : "Suivant"}
                            </>
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
}
