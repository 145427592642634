import * as Yup from "yup"
import createPlateformFormModel from "./createPlateformFormModel"
const {
  formField: { firstName, lastName, email, phone, societyName, plateformName, plateformUrl, logo, primaryColor },
} = createPlateformFormModel

export default [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [email.name]: Yup.string().email(`${email.invalidEmail}`).required(`${email.requiredErrorMsg}`),
    [phone.name]: Yup.string().required(`${phone.requiredErrorMsg}`),
    [societyName.name]: Yup.string().required(`${societyName.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [plateformUrl.name]: Yup.string().required(`${plateformUrl.requiredErrorMsg}`),
    [logo.name]: Yup.string().required(`${logo.requiredErrorMsg}`),
    [primaryColor.name]: Yup.string().required(`${primaryColor.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [plateformName.name]: Yup.string().required(`${plateformName.requiredErrorMsg}`),
    [plateformUrl.name]: Yup.string().required(`${plateformUrl.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [logo.name]: Yup.string().required(`${logo.requiredErrorMsg}`),
    [primaryColor.name]: Yup.string().required(`${primaryColor.requiredErrorMsg}`),
  }),
]
