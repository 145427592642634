import { Box, Button, Typography } from "@material-ui/core"
import React from "react"
import { useHistory } from "react-router-dom"
import FullHeightCenter from "../../FullHeightCenter"

function NotFoundPage() {
  const history = useHistory()

  const handleBackClick = () => {
    history.push(`/`)
  }

  return (
    <FullHeightCenter>
      <Box p={2}>
        <Typography variant='h3' component='p' gutterBottom>
          404 Page non trouvée
        </Typography>
        <Typography variant='subtitle1' gutterBottom>
          Aïe, cette page n'existe pas ou a été déplacée
        </Typography>
        <Box mt={2}>
          <Button onClick={handleBackClick} variant='contained' color='primary'>
            Retour à l'accueil
          </Button>
        </Box>
      </Box>
    </FullHeightCenter>
  )
}

export default NotFoundPage
